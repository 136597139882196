"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Has implicit dependency to `bronson.Notification' for closing behaviour.
 */
var NBWNotification = /** @class */ (function () {
    function NBWNotification(notification) {
        var _this = this;
        this._notification = notification;
        this._pageId = this._notification.dataset.pageId;
        this._closeButton = this._notification.querySelector('#js-dmp-notification-close');
        this._closeButton.id = this._pageId + '_close';
        this._closeButton.onclick = function () {
            sessionStorage.setItem(_this._closeButton.id, 'notificationClosed');
        };
        if (!sessionStorage.getItem(this._closeButton.id)) {
            this._notification.classList.remove('u-hide');
        }
    }
    return NBWNotification;
}());
exports.default = NBWNotification;
/**
 * ON DOCUMENT LOADED
 */
document.addEventListener('DOMContentLoaded', function () {
    console.debug('INFO notification.js loaded');
    var notificationEL = document.querySelector('.js-dmp-notification');
    if (notificationEL) {
        try {
            new NBWNotification(notificationEL);
        }
        catch (e) {
            console.error('An error while creating a new NBW_Notification instance: {}', e);
        }
    }
});
